import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';

import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
// import AddHomevisit from '../AddHomevisit'
// import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
import 'react-multi-carousel/lib/styles.css';
import { Typewriter } from 'react-simple-typewriter'
import "./Home.css";
import baby from "../images/babynew.jpeg";
import Services from './Services';
import Sliderrun from '../inc/Sliderrun';
import Xraypage from "../images/X-ray.jpeg"
import Doctorconsultpage from "../images/g gupta.jpeg";
import Pathologydepartment from "../images/Pathology.jpeg";
import Ggupta from "../images/g gupta.jpeg";

import Doctorvisit from "../images/doctor-consult.jpg"
import ReactPlayer from "react-player";
import USGdepartment from "../images/USG department.jpg";
import depertwisephoto from "../images/Pathology.jpeg"
import Xray from '../inc/Xray';
import PathologyD from '../inc/PathologyD';
import { Contactdata } from './Contactdata';

import AddHomevisit from '../AddHomevisit';
import Usg from '../inc/Usg';


function Home() {








  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>


      <Navbar />

      <br />
      <br />
      <br />
      <br />
      <div style={{ color: "Green" }} className="fixed-bottom "> <a className="buttuncallc" href='tel: +91 9332701212'>Book Appointment  <div className="spinner-grow text-light spinner-grow-sm" role="status">
        <span className="visually-hidden"> Loading...</span>
      </div></a></div>

      <ScrollToTop smooth top="100" color="light" />
      <div className="album py-3">
        <div className="container">

          <div className="row">
            <div className="col-md-7 mt-3">
              <Slider />
            </div>
            <div className="col-md-5 mt-3">
              <Contactdata />
            </div>
          </div>

        </div>
      </div>
      <div className='backgcolor'>
        <div className="album py-1">
          <div className="container">
            <AddHomevisit />
          </div>
        </div>
      </div>
      
      <Services />
      <br />


      <div className="p-1 mb-0 bg-light text-dark">

        <div className="album py-0">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="card-body">
                  <h2 className="card-text mb-2" style={{ paddingTop: "1rem", margin: "auto 0", color: "Gray" }}> <b> OUR SERVICES <span style={{ color: "darkBlue", fontWeight: "bold", marginleft: '7px' }}>
                    <Typewriter
                      loop
                      cursor
                      cursorStyle="_"
                      typeSpeed={90}
                      deleteSpeed={50}
                      delaySpeed={1000}
                      words={[" LABTEST ", " ALL SPECIALITY DOCTOR", "RADIOLOGY", "PATHOLOGY", "CARDIOLOGY", "NEUROLOGY"]}
                    />
                  </span>
                  </b></h2>

                  <p className="card-text mb-3">Quest Diagnostics - Krishnanagar</p>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>

      <br />
      <br />










      <div className="album py-0">
        <div className="container">

          <h4 style={{ color: "grey" }}  >Department Wise Photo</h4>
        </div>
      </div>


      <div className="album py-0">
        <div className="container">

          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card" >
                <img src={Xraypage} className="card-img-top" alt="X-ray" />
                <div className="card-body">
                  <h4 style={{ color: "grey" }} className="card-title">X-Ray</h4>
                  <button type="button" className="button32 button33" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                    </svg> View Photos (3)
                  </button>
                  <a href='tel: +91 9332701212'>
                    <button className="button32 button33 me-2">Call Now</button>
                  </a>

                  <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Xray />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="button32 button33" data-bs-dismiss="modal">Close</button>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card" >
                <img src={Doctorconsultpage} className="card-img-top" alt="Doctorconsul-photo"/>
                <div className="card-body">
                  <h4 style={{ color: "grey" }} className="card-title">Doctor Consult</h4>

                  <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <img src={Doctorvisit} className="d-block w-100" alt="quest_diagnostics" />
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        
                        <div className="modal-footer">
                          <button className="button32 button33" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">Back to first</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a className="button32 button33" data-bs-toggle="modal" href="#exampleModalToggle" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                    <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                    <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                  </svg>View Photos (1)</a>

                  <a href='tel: +91 9332701212'>
                    <button className="button32 button33 me-2">Call Now</button>
                  </a>
                </div>
              </div>
            </div>


            <div className="col-md-4 mt-3">
              <div className="card" >
                <img src={Pathologydepartment} className="card-img-top" alt="Pathologydepartment" />
                <div className="card-body">
                  <h4 style={{ color: "grey" }} className="card-title">Pathology</h4>
                  <button type="button" className="button32 button33" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z" />
                    </svg> View Photo (4)
                  </button>
                  <a href='tel: +91 9332701212'>
                    <button className="button32 button33 me-2">Call Now</button>
                  </a>

                  <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <PathologyD />
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="button32 button33" data-bs-dismiss="modal">Close</button>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>



{/* //4 */}

            <div className="col-md-4 mt-3">
              <div className="card" >
                <img src={USGdepartment} className="card-img-top" alt="USGdepartment"/>
                <div className="card-body">
                  <h4 style={{ color: "grey" }} className="card-title">USG Department</h4>
                  <a href='tel: +91 9332701212'>
                    <button className="button32 button33 me-2">Call Now</button>
                  </a>        
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <br />
      <div className="album py-2">
        <div className="container">
          <h2 style={{color:"grey"}}><b>Dr. Palas De Facebook</b></h2>
          </div>
        </div>

      <div className="album py-3">
        <div className="container">
          <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F61551538941963%2Fvideos%2F426486870378935%2F&show_text=false&width=560&t=0" width="100%" height="280" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
        </div>
      </div>
      
      <div className="album py-2">
        <div className="container">
          <h2 style={{color:"grey"}}><b>Dr. Palas De Youtube Channel</b></h2>
          </div>
        </div>

      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
            <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=5r4WtLzPbZk"/>

            </div>
            <div className="col-md-4 mt-3">
            <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=vhw4xrlBbCo"/>

            </div>
         
            <div className="col-md-4 mt-3">
            <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=xx9GK8c0K44"/>

            </div>
         
          </div>
        </div>
      </div>
      <br/>

      <section className="section featured-car" id="healthpackage">
        <div className="album py-3">
          <div className="container">

            <div className="row">
              <center>
                <div className='colortext'>
                  <h2 ><b>HEALTH PACKAGE </b></h2>
                </div>
              </center>
            </div>
          </div>
        </div>
      </section>
      <center> <h3 className='' style={{ color: "grey" }}>Update soon</h3></center>
      <br />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div class="card mb-3" >
                <div className="row g-0">
                  <div className="col-md-5">
                    <div className='hoverimage'>
                      <img src={baby} className="img-fluid rounded-start image1" alt="bestothopaedicdoctorkrishnanagar" />
                      <div className="overlay">New Baby Guide <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg></div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="card-body">
                      <div className="textcolor">
                        <h4 className="card-title mb-2"><b>NEW BABY GUIDE</b></h4>
                      </div>
                      <p style={{ color: "grey" }} className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                      </svg> Consultant Child Specialist</p>

                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Food & Lifestyle
                            </button>
                          </h2>
                          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              Update Soon
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Dialy Routine
                            </button>
                          </h2>
                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              We will be up and running shortly Loading...
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              Skin & Hair
                            </button>
                          </h2>
                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              We will be up and running shortly Loading...
                            </div>
                          </div>
                        </div>
                      </div>
                      <a href='tel: 9332701212' className="buttoncall mt-3" type="submit">Book an Appointment</a>

                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div className="col-md-3 mt-0">

              {/* <Others /> */}
              <div class="sticky-top" >
                <div class="card">
                  {/* <Sliderortho/> */}
                  <Sliderrun />


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <br />
    </div>




  )
}

export default Home